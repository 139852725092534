import React from "react"
import faqImg from "../images/components/tipsFaq/faq.png"
import tipsImg from "../images/components/tipsFaq/tips.jpg"

const TipsFaq = ({type, header, subheader, children}) => {
  let icon = (type === "faq") ? faqImg : tipsImg;
  return (    
      <div className="container mb-5 mt-0 py-0 py-sm-3">
        <div className="d-flex flex-column">
          <div className="col-12 px-0 d-flex flex-column flex-lg-row">
            <div className="col-lg-2 px-0 d-flex justify-content-center align-items-center">
              <img src={icon} className="tipsFaq-icon"/>
            </div>
            <div className="col-lg-10 px-0 align-items-center tipsFaq-subheader-container justify-content-center justify-content-lg-start">
              <p className="text-primary text-size-50 font-weight-bold ml-md-0 my-0 tipsFaq-header pt-lg-3 mt-lg-5 text-center text-lg-left">{header}</p>
              <p className="text-size-32 text-gray text-center text-lg-left">{subheader}</p>
            </div>
          </div>
        </div>
        <div className="d-flex">
          <div className="d-flex flex-column flex-lg-row offset-lg-2">
              {[children]}
          </div>
        </div>
      </div>
  )
}

export default TipsFaq