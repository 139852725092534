import React from "react"
import useTranslations from "../../utils/useTranslations"
import ComparisonPanels from "../../components/comparisonPanels"
import FeaturesList from "../../components/featuresList"

import heroImgWebp from "../../images/products/heroes/ui-web-pro.webp"
import heroImg from "../../images/products/heroes/ui-web-pro.png"
import heroImgWebp2x from "../../images/products/heroes/ui-web-pro@2x.webp"
import heroImg2x from "../../images/products/heroes/ui-web-pro@2x.png"


import Button from "../../components/button"
import Logo from "../../components/logo"
import backgrounds from "../../components/backgrounds"
import TipsFaq from "../../components/tipsFaq"
import {WebPro} from "../../components/cartLinks";


const heroImgAlt = "Buy µTorrent Web Pro"

class BittorrentWebPremium extends React.Component {

  constructor(props){
    super(props)
    this.state = {
      isMac: false,
    };
  }

  componentDidMount(){
    this.setState({
      isMac: window.gatsbyIsMac,
    })
  }

  render(){

    const { text, pageName } = this.props.pageContext
    const t = useTranslations(text)
    const { unlocalizedPath }  = this.props.pageContext

    const webProLink = (this.state.isMac) ? WebPro.webMac : WebPro.webWin

    return (
      <>
        <div className="top-section-container pt-md-2">

          <div className="container text-dark py-2">

            <div className="d-flex flex-column justify-content-center text-center mt-4 my-sm-4">
              <div className="col-md-12 mt-md-4 order-md-1 px-md-0">
                <Logo secondaryColor="black" tag="p" productName="Web Pro" className="mb-4" fsMax={28} fsCoefficient={24} fixedParentDistance={3}/>

                <img src={heroImg} alt={heroImgAlt} className="img-fluid d-block d-md-none mb-2 ml-auto shadow-lg rounded-xl"></img>
                <div style={{fontSize: '10px'}} className="d-block d-md-none mt-2 mb-4"><a id="web-pro-bt-now-mobile" href="https://now.bt.co/" target="_blank" rel="noopenner noreferrer" className="text-dark text-decoration-none">now.bt.co</a> content displayed pursuant to license.</div>
                <div className="text-size-24 text-spacing-1 font-weight-normal">
                  <h1 className="text-size-24 font-weight-normal m-0 d-inline">{t('Get added protection from malware and viruses.')}</h1>
                  <h2 className="text-size-16 font-weight-normal mt-3">{t('Advanced security to block threats')}</h2>
                </div>
                <Button  href={`${webProLink}?coupon-code-to-add=e76a5a26-d373-45a5-9922-69ca81807091`} id="products-win-utweb-premium-hero-cta" className=" btn-primary mt-3 mt-md-4 mb-4 button-filled text-spacing-1 px-3 text-unset">{t(`Get Pro for $19.95/year`)}</Button>
              </div>
              
            </div>
            <div className="col-md-12 d-none d-md-block mb-5 order-md-2">
                {/*<img src={heroImg} alt={heroImgAlt} className="img-fluid d-none d-md-block"></img>*/}

                <picture className="d-flex justify-content-center">
                  <source srcSet={`${heroImgWebp} 1x, ${heroImgWebp2x} 2x`} type="image/webp"></source>
                  <img srcSet={`${heroImg} 1x, ${heroImg2x} 2x`} src={heroImg} alt={heroImgAlt} className="img-fluid d-none d-md-block shadow-lg rounded-xl"></img>
                </picture>
                <div style={{fontSize: '10px'}} className="d-none d-md-block mb-4 text-center mt-2"><a id="web-pro-bt-now" href="https://now.bt.co/" target="_blank" rel="noopenner noreferrer" className="text-dark text-decoration-none">now.bt.co</a> content displayed pursuant to license.</div>

              </div>
          </div>
        </div>

        <div className="container-fluid overflow-hidden position-relative">
          <div className="container z-2">
            
            <div className="container d-flex justify-content-center align-items-center py-2 px-0 my-4 mt-sm-5">
                <div className="col-12 order-md-1 d-flex flex-column justify-content-center align-items-center">
                  <h2 className="font-weight-bold text-md-left feature-list-title">{t(`Features`)}</h2>
                  <p className="font-weight-normal mb-3 mt-3 text-center text-md-left feature-list-title-description">{t(`µTorrent Web Pro Features`)}</p>
                </div>
              </div>

            <div className = "index-features-list m-auto pb-5">
              <FeaturesList text={text} pageName={pageName}/>
            </div>
          </div>
        </div>

        <div className="container-fluid position-relative overflow-hidden px-0 pb-5 pb-sm-0" style={backgrounds.lightBg}>
        <div className="container-fluid panel-bg">
          <div className="container my-5 z-2">
            <p className="text-center font-weight-bold feature-list-title mb-3 pt-3">{t(`Compare`)}</p>
            <h2 className="text-center mb-5 text-size-24 font-weight-normal text-dark">
                {t(`Compare µTorrent Web Versions`)}
            </h2>
          </div>
          <ComparisonPanels pageContext={{ "text": text, "pageName":pageName, "isMac": this.state.isMac}}/>
        </div>
        </div>

        <TipsFaq type="tips" header={t(`Tips`)} subheader={t(`µTorrent Web Pro Product Tip`)}>
          <div className="col-12 px-0">
            <p className="product-faq-question">{t(`What makes µTorrent Web Pro better than the free version?`)}</p>
            <p className="product-faq-answer">{t(`Building on the features that make uTorrent Web the leading online torrent downloader and player, the new Pro version seamlessly integrates an in-client AV Scanner, safeguarding your device from potential malware and viruses, thereby ensuring a secure torrenting experience. Our pro users now enjoy heightened security, an ad-free interface, and premium customer support, all in one comprehensive package. For those seeking more, we also offer µTorrent Web Pro+VPN that includes one year of CyberGhost VPN on up to 5 devices. This ensures online privacy on your desktop computer, mobile phone, tablet, and other internet-connected devices.`)}</p>
          </div>
        </TipsFaq>

      </>
    )

  }
}

export default BittorrentWebPremium
